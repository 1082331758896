module controllers {
    export module master {

        interface IRequirementGroupListCtrlScope extends ng.IScope {
        }

        interface IRequirementGroupListParams extends ng.ui.IStateParamsService {
        }

        export class requirementGroupListCtrl {

            static $inject = ["$scope",
            "$rootScope",
            "generalService",
            "$q",
            "bsLoadingOverlayService",
            "$timeout",
            '$uibModal',
            'requirementGroupService',
            '$state',
            '$stateParams',
            '$transitions'
            ];

            IsLoading: boolean = false;

            gridApi: uiGrid.IGridApi;
            myHook: any;

            apiList: uiGrid.IGridApi;

            filterNames: string[] = ["EntityCode","TransactionType","Code","Name","ActionType","IsActive"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];
            sortName: string;
            sortDirection: string;

            constructor(private $scope: IRequirementGroupListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private requirementGroupService: interfaces.master.IRequirementGroupService,
                private $state: ng.ui.IStateService,
                private $stateParams: IRequirementGroupListParams,
                private $transitions: ng.ui.core.ITransition
            ) {
                this.loadRequirementGroups();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.RequirementGroup',
                    from: 'auth.MasterData.RequirementGroup.**'
                }, () => {
                        this.loadRequirementGroups();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadRequirementGroups() {
                
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'requirementGroup'
                },
                    () => {
                        return this.requirementGroupService.getList().query((data) => {
                            this.IsLoading = false;
                            this.gvwList.data = data;

                            this.$timeout(() => {
                                if (this.apiList) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.getFilters(); this.setFilters(); });
            }

            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi)
                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                             <div class="GridButton">
                                <button type="button" ng-click="grid.appScope.requirementGroupListCtrl.editRequirementGroup(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 80,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "Code",
                    displayName: "Code",
                    field: "Code",
                    width: 150,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "Name",
                    displayName: "Name",
                    field: "Name",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "TransactionType",
                    displayName: "TransactionType",
                    field: "TransactionType",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "ActionType",
                    displayName: "Action",
                    field: "Action",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                },
                {
                    name: "IsActive",
                    displayName: "Active",
                    field: "IsActive",
                    width: 120,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }

                ]
            };

            public editRequirementGroup(id: number) {
                this.$state.go("auth.MasterData.RequirementGroup.Update", { requirementGroupId: id });
            }

            AddNew() {
                this.$state.go("auth.MasterData.RequirementGroup.Update", { requirementGroupId: 0 });
            }

            downloadToExcel(){
                this.IsLoading = true;

                this.requirementGroupService.getListExcel().then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    
                });
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiList.grid.getColumn(name).filters[0].term || this.apiList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.apiList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

        }

        angular.module("app").controller("requirementGroupListCtrl", controllers.master.requirementGroupListCtrl);
    }
}